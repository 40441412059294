body,
html {
  position: initial;
  top: 0px !important;
  margin: 0;
  padding: 0;
  min-height: 100%;
  height: 100%;
  width: 100%;
  font-size: 13px !important;
  line-height: 1.57142857 !important;
  overflow: hidden !important;
}

article,
aside,
dialog,
figcaption,
figure,
footer,
header,
hgroup,
main,
nav,
section {
  display: block;
}

[tabindex="-1"]:focus {
  outline: 0 !important;
}

/*Scroll Bar styles */

::-webkit-scrollbar-track {
  background-color: transparent;
}

::-webkit-scrollbar {
  width: 5px;
  height: 8px;
  background-color: transparent;
}

::-webkit-scrollbar-thumb {
  background-color: #ccc;
}

.main-wrapper {
  position: relative;
  top: 0;
  height: 100vh;
}

.sub-title {
  margin-top: 0 !important;
  font-size: 14px !important;
  margin-bottom: 0 !important;
  padding-top: 0 !important;
  text-transform: uppercase;
  font-weight: 500 !important;
  letter-spacing: 0.3px;
}

.middle-container {
  height: 100%;
  max-height: 100%;
  background: #f2f2f2 !important;
}

.middle-container .content {
  padding: 15px 15px 50px 260px;
}

.mat-button,
.mat-raised-button {
  font-size: 13px !important;
  line-height: 30px !important;
  padding: 0 15px !important;
  min-width: 30px !important;
  text-transform: uppercase;
}

.rt-buttons button + button {
  margin-left: 8px;
}

.view-layout [class*="col-md-"] {
  margin-bottom: 15px !important;
}

.view-layout .rt-no-mb {
  margin-bottom: 0 !important;
}
.rt-primary-bg {
  background-color: #f1efff;
}

/*  modal dialog styles */

.modal-dialog-wrapper .mat-mdc-dialog-container {
  padding: 0;
  overflow: hidden;
}
.modal-dialog-wrapper .mat-mdc-dialog-surface{
  overflow: hidden;
}
.mat-dialog-container .mat-mdc-dialog-content {
  margin: 0;
  padding: 0;
  max-height: 500px;
  margin-right: 5px;
}
td.cdk-cell,
th.cdk-header-cell {
  padding: 0;
  border-bottom-width: 1px;
  border-bottom-style: solid;
  text-align: left;
}

.cdk-table td.cdk-cell,
.cdk-table th.cdk-header-cell {
  border-bottom-color: rgba(0, 0, 0, 0.12) !important;
}

body .cdk-table.disable-hover tr.cdk-row:hover {
  background: transparent !important;
}

.cdk-table {
  margin: 0 !important;
  width: 100%;
  table-layout: fixed;
}

.cdk-table .cdk-header-row,
.cdk-table .cdk-row {
  min-height: auto;
}

.cdk-table .cdk-header-row {
  height: 42px;
}

.cdk-table .cdk-header-cell {
  vertical-align: top !important;
  height: auto;
  padding-top: 10px !important;
}

.cdk-table .cdk-header-cell,
.cdk-table .cdk-cell {
  min-height: 56px;
  min-width: auto;
  padding: 0 5px;
  vertical-align: middle;
}

.rt-table-columns-title {
  min-height: 25px;
  display: block;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  text-transform: uppercase;
}

.table-column-title {
  font-weight: 500 !important;
  font-size: 13px !important;
  letter-spacing: 0.3px;
}
