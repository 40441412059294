/* You can add global styles to this file, and also import other style files */

html,
body {
  height: 100%;
}

body {
  padding: 0;
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 13px;
}

.rt-min-height-20 {
  min-height: 20px !important;
}
.rt-min-height-15 {
  min-height: 15px !important;
}
.rt-height-15 {
  height: 15px !important;
}
.rt-min-height-10 {
  min-height: 10px !important;
}
.rt-min-height-5 {
  min-height: 5px !important;
}

.rt-height-100 {
  height: 100px !important;
}


.rt-min-height-500 {
  min-height: 400px !important;
}

.rt-min-width-300 {
  min-width: 300px !important;
}

.snackbar-css{
  background: #ffffff;
  margin-top: 20px;
  color: purple;
}




