
// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use '@angular/material' as mat;
@import "@angular/material/theming";
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat-core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$my-blue: (
  50: #444e73,
  100:#444e73,
  200:#444e73,
  300: #444e73,
  400: #444e73,
  500: #444e73,
  600: #444e73,
  700: #444e73,
  800: #444e73,
  900: #444e73,
  A100: #444e73,
  A200: #444e73,
  A400: #444e73,
  A700: #444e73,
  contrast: (
    50: white,
    100: white,
    200: white,
    300: white,
    400: white,
    500: white,
    600: white,
    700: white,
    800: white,
    900: white,
    A100: white,
    A200: white,
    A400: white,
    A700: white,
  )
);
// below creates a primary palette with three shades of blue
$devum-primary: mat-palette($my-blue, 100, 50, 200);

//$devum-primary: mat-palette($mat-blue-grey, 700);
$devum-accent: mat-palette($mat-indigo, A200, A100, A400);
// The warn palette is optional (defaults to red).
$devum-warn: mat-palette($mat-red);


// Create the theme object (a Sass map containing all of the palettes).
$devum-theme: mat-light-theme($devum-primary, $devum-accent, $devum-warn);



// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include angular-material-theme($devum-theme);

//@include mat-all-component-themes($devum-theme);
$primary: map-get($devum-theme, primary);
$accent: map-get($devum-theme, accent);

.primary-bg{
  background: mat-color($primary);
 }
.secondary-bg{
  background: mat-color($accent);
 }

 .console-page-slide-panel-wrapper .page-slide-panel-wrapper {
  z-index: 9 !important;
}

.rt-primary-border {
  border: 1px solid mat-color($primary, 100) !important;
}


